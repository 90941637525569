import React from 'react';
import styled from 'styled-components';

import TimelineContent from './content';
import useScrollContent from '../../../hooks/useScrollContent';
import { mediaQuery, standardStyles } from '../../../utils/styles';

// each content is 150vh, total height is 6*150 + 10 (extra) = 910vh
const Wrap = styled.div`
  position: relative;
  background: ${standardStyles.colorPrimary};
  color: ${standardStyles.fontColorPrimary};
  height: 910vh;
  text-align: center;
`;

// outer sticky wrap
const StickyWrap = styled.div`
  -webkit-position: sticky;
  position: sticky;
  width: 100%;
  left: 50%;
  top: 0%;
`;

// translatesX when user scrolls
const ScrollContentWrap = styled.div.attrs((props) => ({
  style: {
    opacity: props.scroll > 10 && props.scroll < 100 ? 1 : 0,
  },
}))`
  position: fixed;
  top: 0%;
  transition: 0.5s;
  width: 50%;
  left: 25%;
  height: 0.5rem;
  background: ${standardStyles.colorPrimary};
  border-radius: 2rem;
  overflow: hidden;
`;

const ScrollContentBar = styled.span.attrs((props) => ({
  style: {
    transform: `translateX(${props.scroll}%)`,
  },
}))`
  position: absolute;
  transition: 0.5s;
  height: 0.5rem;
  width: 100%;
  background: ${standardStyles.colorQuinary};
  border-radius: 2rem;
  left: -100%;
`;

export default function Timeline() {
  const { ref, scrollContent } = useScrollContent(false);

  const firstContent = `
  I graduated in Chemical Engineering at Nanyang Technological University in 2018.
  It seemed appropriate and safe, to pursue something I studied, but that didn't work out.
  Instead, I embarked on a new journey as a Process Engineer at Micron Technology.
  Despite facing a steep learning curve, I dedicated myself to mastering my role and took immense pride in my responsibilities.
  During my tenure, I honed my skills in problem-solving, data mining, and analysis through various engineering projects.
  However, as I thought about the next chapter of my professional life, I yearned for something different.
  A career that aligns with my values and allows me to make a meaningful impact.
  `;

  const secondContent = `
  In January 2021, I was presented with an exciting opportunity to contribute to the scaling up of a web application project at Micron, involving the migration from monolithic architecture to microservices.
  While I had been intrigued by programming, I always found it challenging to grasp and applying in real-world scenarios due to its abstract nature. 
  However, fueled by curiosity and a desire to explore new horizons, I challenged myself and accepted the task. 
  This marked the beginning of my coding journey, which I embarked upon with Python Flask.
  `;

  const thirdContent = `
  An evolving world without limits, with endless opportunities and applications, incorporating creativity and problem solving.
  Behind the scenes, programming has catalyzed societal and technological advancements.
  We are hopelessly dependent on it.
  The monumental impact programming can create piqued my interest and I found myself drawn to the world of programming.
  Eager to explore its potential, I immersed myself in mastering the fundamentals of vanilla Python, Javascript, CSS, HTML, and SQL, investing countless hours into building a solid foundation.
  Udemy was exceptionally helpful.
  `;

  const fourthContent = `
  Eager to broaden my skill set, I embarked on a journey of continuous learning.
  I delved into a multitude of technologies, including Bootstrap, React, Angular, FastAPI, ExpressJS, Gin, Celery, Docker, ELK, Jenkins, and more. 
  Alongside, I ventured into Golang and acquainted myself with the Linux operating system.
  `;

  const fifthContent = `
  To translate my theoretical knowledge into practical skills, I engaged in various mock full-stack projects, including the development of a shopping app platform and a stock screener. 
  These projects afforded me hands-on experience with CSS, LeetCode for data structures and algorithms, and a comprehensive exploration of object-oriented programming, design patterns, APIs, software architectures, DevOps, and networking.
  At Micron, I completed the web application migration to microservices.
  The application became an indispensable tool for both process engineers and technicians, automating their work across Micron sites globally, and achieved a significant productivity gain of 800 manhour savings per week.
  Fullstack development resonates deeply with my passions, and I continuously seek out knowledge to stay forefront of the evolving technologies in software engineering.
  `;

  const sixthContent = `
  My journey has just started.
  There is still a world of unexplored territories, and I have only scratched the surface.
  Moving forward, I am looking for my next adventure into software development.
  `;

  return (
    <Wrap ref={ref} id="timeline">
      <ScrollContentWrap scroll={scrollContent}>
        <ScrollContentBar scroll={scrollContent}></ScrollContentBar>
      </ScrollContentWrap>
      <StickyWrap></StickyWrap>

      <TimelineContent heading="PRELUDE" digit={0} content={firstContent} />
      <TimelineContent heading="VIRGIN" digit={1} content={secondContent} />
      <TimelineContent heading="FOUNDATION" digit={2} content={thirdContent} />
      <TimelineContent
        heading="EXPLORATION"
        digit={3}
        content={fourthContent}
      />
      <TimelineContent heading="PRACTICE" digit={4} content={fifthContent} />
      <TimelineContent heading="PRESENT" digit={5} content={sixthContent} />
    </Wrap>
  );
}
